import React, { useState, useEffect } from 'react';
import { 
    StyleSheet, Text, View, ScrollView, Platform
} from 'react-native';

const ItemsPage = ({ userEmail }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (Platform.OS === 'web') {
            window.history.pushState({}, '', '/items');
        }
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            let response = await fetch('https://skeba.info/costbrain/allUserItems.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userEmail })
            });
            let json = await response.json();
            setItems(json);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    return (
        <View style={styles.container}>
            <Text style={styles.messageText}>Item List</Text>
            <Text style={styles.emailText}>User Email: {userEmail}</Text>

            <ScrollView style={styles.itemsContainer}>
                {items.map((item, index) => (
                    <View key={index} style={styles.itemBox}>
                        <Text>{item.Item_Name}</Text>
                        <Text>Item #: {item.Item_Number} || Size: {item.Item_Unit} </Text>
                    </View>
                ))}
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    itemsContainer: {
        marginTop: 20,
        width: 300, // Ensuring the ScrollView takes the full width
    },
    itemBox: {
        backgroundColor: '#dddddd',
        padding: 10,
        marginTop: 10,
        borderRadius: 5,
        marginHorizontal: 10, // Added for spacing from the screen edges
    },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
    },
    messageText: {
        fontSize: 18,
        textAlign: 'center',
        color: '#333',
        marginTop: 20, // Adjusted spacing
    },
    emailText: {
        fontSize: 14,
        textAlign: 'center',
        color: '#666',
        marginBottom: 20, // Added some space below the email text
    },
    // ...add any other styles you might need
});

export default ItemsPage;
