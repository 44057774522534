import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, Picker, StyleSheet, Platform, ImageBackground  } from 'react-native';


import AsyncStorage from '@react-native-async-storage/async-storage';


import ReactNiceAvatar, {
    genConfig
  } from "react-nice-avatar";

const UserProfilePage = ({ userProfile }) => {
    const [profileData, setProfileData] = useState(null);

    useEffect(() => {
        if (!profileData) {
          fetchUserProfileData();
        }
      }, []);
    
    
      const fetchUserProfileData = async () => {
        // Assuming userProfile.email contains the user's email
        const email = userProfile?.email;
        if (!email) {
          console.error('No email provided');
          return;
        }
        try {
            const cachedProfile = await AsyncStorage.getItem(`profileData_${email}`);
            if (cachedProfile) {
              setProfileData(JSON.parse(cachedProfile));
            } else {
        fetch(`https://skeba.info/costbrain/profile.php?email=${encodeURIComponent(email)}`)
          .then(response => response.json())
          .then(data => {
            setProfileData(data);

            // Update the state with the fetched data
            setUsername(data.username || '');
            setTimeZone(data.timezone || 'UTC');
    
            // Assuming data.avatar is a JSON string of avatar configuration
            updateAvatarState(data.avatar || '{}');
          })
          .catch(error => {
            console.error('Error fetching profile data:', error);
          });
        }
    } catch (error) {
      console.error('Error accessing AsyncStorage:', error);
      // Optionally, handle error or fetch from server
    }
  };
  const [timeZone, setTimeZone] = useState('UTC');
  const [username, setUsername] = useState('');
  const background = require('./profilebackground.png'); // Path to your background image
  const [saveMessage, setSaveMessage] = useState('');
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    // State variables for avatar attributes
    const [eyeStyle, setEyeStyle] = useState("circle");
    const [noseStyle, setNoseStyle] = useState("short");
    const [hairStyle, setHairStyle] = useState("womanLong");
    const [mouthStyle, setMouthStyle] = useState("smile");
    const [shirtStyle, setShirtStyle] = useState("short");
    const [glassesStyle, setGlassesStyle] = useState("none");
    const [hairColor, setHairColor] = useState("black");
    const [shirtColor, setShirtColor] = useState("black");
  // State variable for face color
  const [faceColor, setFaceColor] = useState("#FFE0BD");

  // Array of skin color options
  const skinColorOptions = [
    "#FFE0BD", "#FFCD94", "#FFF1E0", "#D2B48C", "#C68642",
    "#E0AC69", "#8D5524", "#5C3836", "#40241A"
  ];

    // Arrays of options
    const eyeOptions = ["circle", "oval", "smile"];
    const noseOptions = ["short", "long", "round"];
    const hairOptions = ["normal", "thick", "mohawk", "womanLong", "womanShort"];
    const mouthOptions = ["laugh", "smile", "peace"];
    const shirtOptions = ["hoody", "short", "polo"];
    const glassesOptions = ["none", "round", "square"];
    const hairColorOptions = ["brown", "red", "orange", "blue", "pink", "black", "yellow", "purple"];
    const shirtColorOptions = ["white", "black", "blue", "red", "green", "yellow", "purple", "grey", "orange"];
    const updateAvatarState = (avatarJson) => {
        try {
          // Parse the JSON string into an object
          const config = JSON.parse(avatarJson);
      
          // Update each state variable with the corresponding value from the config
          setEyeStyle(config.eyeStyle || "circle");
          setNoseStyle(config.noseStyle || "short");
          setHairStyle(config.hairStyle || "long");
          setMouthStyle(config.mouthStyle || "smile");
          setShirtStyle(config.shirtStyle || "short");
          setGlassesStyle(config.glassesStyle || "none");
          setHairColor(config.hairColor || "black");
          setShirtColor(config.shirtColor || "black");
          setFaceColor(config.faceColor || "#FFE0BD");
          // ... and so on for other attributes
        } catch (error) {
          console.error('Error parsing avatar configuration:', error);
        }
      };
    const cycleFaceColor = () => {
        const currentIndex = skinColorOptions.indexOf(faceColor);
        const nextIndex = (currentIndex + 1) % skinColorOptions.length;
        setFaceColor(skinColorOptions[nextIndex]);
      };
    
      const cycleEyeStyle = () => {
        const currentIndex = eyeOptions.indexOf(eyeStyle);
        const nextIndex = (currentIndex + 1) % eyeOptions.length;
        setEyeStyle(eyeOptions[nextIndex]);
      };
      const cycleNoseStyle = () => {
        const currentIndex = noseOptions.indexOf(noseStyle);
        const nextIndex = (currentIndex + 1) % noseOptions.length;
        setNoseStyle(noseOptions[nextIndex]);
      };
    
      const cycleHairStyle = () => {
        const currentIndex = hairOptions.indexOf(hairStyle);
        const nextIndex = (currentIndex + 1) % hairOptions.length;
        setHairStyle(hairOptions[nextIndex]);
    
        // Select a random hair color
        const randomColor = hairColorOptions[Math.floor(Math.random() * hairColorOptions.length)];
        setHairColor(randomColor);
      };
      const cycleMouthStyle = () => {
        const currentIndex = mouthOptions.indexOf(mouthStyle);
        const nextIndex = (currentIndex + 1) % mouthOptions.length;
        setMouthStyle(mouthOptions[nextIndex]);
      };
    
      const cycleShirtStyle = () => {
        const currentIndex = shirtOptions.indexOf(shirtStyle);
        const nextIndex = (currentIndex + 1) % shirtOptions.length;
        setShirtStyle(shirtOptions[nextIndex]);
    
        // Select a random shirt color
        const randomColor = shirtColorOptions[Math.floor(Math.random() * shirtColorOptions.length)];
        setShirtColor(randomColor);
      };
    
      const cycleGlassesStyle = () => {
        const currentIndex = glassesOptions.indexOf(glassesStyle);
        const nextIndex = (currentIndex + 1) % glassesOptions.length;
        setGlassesStyle(glassesOptions[nextIndex]);
      };
    

      const config = {
        sex: "woman",
        faceColor: faceColor,
         earSize: "small",
         eyeStyle: eyeStyle,
         noseStyle: noseStyle,
         mouthStyle: mouthStyle,
         shirtStyle: shirtStyle,
         glassesStyle: glassesStyle,
         hairColor: hairColor,
         hairStyle: hairStyle,
         hatStyle: "none",
         hatColor: "#F48150",
         eyeBrowStyle: "up",
         shirtColor: shirtColor,
         bgColor: "linear-gradient(45deg, #3e1ccd 0%, #ff6871 100%)"
       };
  const myConfig = genConfig(config);
  const [serverMessage, setServerMessage] = useState('');

  useEffect(() => {
    if (Platform.OS === 'web') {
      window.history.pushState({}, '', '/profile');
    }
  }, []);
const handleSave = () => {
    setSaveMessage('');
  const userData = {
    email: userProfile?.email,
    username: username,
    timezone: timeZone,
    avatar: config,
  };

  // Making an API call to the server
  fetch('https://skeba.info/costbrain/profile.php', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(userData)
  })
  .then(response => response.json())
  .then(data => {
    // Check the specific response message from the server
    if (data.message === "Profile updated successfully") {
      setIsSaveSuccessful(true);
      setSaveMessage(data.message);

      // Clear the message after 3 seconds
      setTimeout(() => {
        setSaveMessage('');
      }, 3000);
    } else {
      setIsSaveSuccessful(false);
      // Use the specific error message returned by the server
      setSaveMessage(data.message || 'An error occurred while saving');
    }
  })
  .catch(error => {
    console.error('Error saving profile:', error);
    setSaveMessage('Network or server error');

  });
};

  return (
    <ImageBackground source={background} style={styles.background}>
    <View style={styles.container}>
      <View style={styles.profileBox}>
        <Text style={styles.header}>User Profile</Text>
        <Text style={styles.info}>Name: {userProfile?.name}</Text>
        <Text style={styles.info}>Email: {userProfile?.email}</Text>

        <View style={styles.avatarContainer}>
        <ReactNiceAvatar
          style={styles.avatarStyle}
          {...myConfig}
        />
      </View>
       <View style={styles.iconContainer}>
     
        </View>
        <TextInput
          style={styles.input}
          onChangeText={setUsername}
          value={username}
          placeholder="Enter username"
        />
        <Picker
          selectedValue={timeZone}
          style={styles.picker}
          onValueChange={(itemValue) => setTimeZone(itemValue)}
        >
          {/* Add your time zone options here */}
          <Picker.Item label="EST (New York)" value="America/New_York" />
  <Picker.Item label="CST (Chicago)" value="America/Chicago" />
  <Picker.Item label="MST (Denver)" value="America/Denver" />
  <Picker.Item label="PST (Los Angeles)" value="America/Los_Angeles" />
  <Picker.Item label="HST (Honolulu)" value="Pacific/Honolulu" />
  <Picker.Item label="AKST (Anchorage)" value="America/Anchorage" />
  <Picker.Item label="GMT (London)" value="Europe/London" />
  <Picker.Item label="CET (Berlin)" value="Europe/Berlin" />
  <Picker.Item label="EET (Athens)" value="Europe/Athens" />
  <Picker.Item label="AST (Riyadh)" value="Asia/Riyadh" />
  <Picker.Item label="IST (New Delhi)" value="Asia/Kolkata" />
  <Picker.Item label="CST (Beijing)" value="Asia/Shanghai" />
  <Picker.Item label="JST (Tokyo)" value="Asia/Tokyo" />
  <Picker.Item label="AEST (Sydney)" value="Australia/Sydney" />
  <Picker.Item label="NZST (Auckland)" value="Pacific/Auckland" />
          {/* etc. */}
        </Picker>
        <Button title="Save" onPress={handleSave} />
        {saveMessage !== '' && (
  <Text style={isSaveSuccessful ? styles.successMessage : styles.errorMessage}>
    {saveMessage}
  </Text>
)}
      </View>
    </View>
    </ImageBackground>
  );
};


const styles = StyleSheet.create({
    background: {
        flex: 1,
        width: null,
        height: null,
        // Repeat and cover the whole screen
        resizeMode: 'repeat', // This will not work as React Native doesn't support 'repeat'
      },
      iconStyle: {
        fontSize: 25,
        padding:5,
        color: '#000',
      },
      avatarContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        // Add any additional styling for the container if needed
      },
      avatarStyle: {
        width: "10rem", 
        height: "10rem",
        // Any additional styling for the avatar
      },
      successMessage: {
        color: 'green', // or any color you prefer
        margin: 10,
        textAlign: 'center'
      },
      errorMessage: {
        color: 'red', // or any color you prefer
        margin: 10,
        textAlign: 'center'
      },
      // ... r
  container: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 100, // 100px from the top
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
  },
  iconContainer: {
    flexDirection: 'row', // Align icons in a row
    justifyContent: 'center', // Center icons horizontally
    alignItems: 'center', // Center icons vertically
    // You can add additional styling like padding or margin as needed
  },
  picker: {
    height: 50,
    margin: 12,
  },
  avatar: {
    width: 80,
    height: 80,
    marginBottom: 10,
    alignItems: 'center', 
    justifyContent: 'center', // Center icons horizontally
  },
  profileBox: {
    maxWidth: 300,
    width: '100%', // Use full width on smaller screens
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // for Android shadow
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  info: {
    fontSize: 16,
    marginBottom: 5,
  },
});

export default UserProfilePage;

