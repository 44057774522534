import React, { useState, useRef, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import axios from 'axios'; // Ensure axios is installed and imported
import AsyncStorage from '@react-native-async-storage/async-storage';


const LoggedInPage = ({ userProfile }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [uploadMessage, setUploadMessage] = useState('');
  const handleChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  useEffect(() => {
    const loadUserProfile = async () => {
      if (!userProfile) {
        try {
          const storedProfile = await AsyncStorage.getItem('userProfile');
          if (storedProfile !== null) {
            setUserProfile(JSON.parse(storedProfile));
          }
        } catch (error) {
          console.error('Failed to load user profile from AsyncStorage', error);
        }
      }
    };

    loadUserProfile();

    if (Platform.OS === 'web') {
      window.history.pushState({}, '', '/home');
    }
  }, [userProfile]);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setUploadMessage(''); // Clear previous upload message
    }
  };

  const uploadFile = async () => {
    if (selectedFile) {
      setIsLoading(true); // Start loading
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('email', userProfile?.email);

      try {
        const response = await axios.post('https://skeba.info/costbrain/pdftotext.php', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // Check the response structure for errors
        if (response.data && response.data.status === 'error') {
          setUploadMessage('Error: ' + response.data.message);
        } else {
          setUploadMessage('File uploaded successfully');
        }
      } catch (error) {
        setUploadMessage('Error uploading file: ' + error.message);
      } finally {
        setIsLoading(false); // Stop loading regardless of the outcome
      }
    } else {
      setUploadMessage('No file selected');
    }
  };
  


  return (
    <View style={styles.container}>
      <View style={styles.messageBox}>
      <Text style={styles.messageText}>Welcome {userProfile?.name || 'User'}!</Text>
      <Text style={styles.messageText}>Email: {userProfile?.email || 'Not available'}</Text>
        <Text style={styles.taskText}>Upload a PDF</Text>

        {Platform.OS === 'web' && (
          <>
                  {isLoading ? (
          <Text style={styles.messageText}>Uploading...</Text>
        ) : (
          <Text style={styles.messageText}>{uploadMessage}</Text>
        )}
            <input
              ref={fileInputRef}
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <TouchableOpacity style={styles.submitButton} onPress={handleChooseFile}>
              <Text style={styles.submitButtonText}>Choose File</Text>
            </TouchableOpacity>

            {selectedFile && (
              <TouchableOpacity style={styles.submitButton} onPress={uploadFile}>
                <Text style={styles.submitButtonText}>Upload</Text>
              </TouchableOpacity>
            )}
          </>
        )}

        <Text style={styles.messageText}>{uploadMessage}</Text>
      </View>
    </View>
  );
};


const styles = StyleSheet.create({
  contributorsContainer: {
    flexDirection: 'row', // Align items in a row
    alignItems: 'center', // Center items vertically
    marginTop: 20,
   
  },
  label: {
    fontSize: 18,
    color: '#333',
  },
  submitButtonContainer: {
    marginTop: 20,
  },
  submitButton: {
    backgroundColor: '#007bff', // Example button color
    padding: 10,
    borderRadius: 10,
    alignItems: 'center',
  },
  submitButtonText: {
    color: 'white', // Example text color
    fontSize: 18,
  },
  contributorsInput: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    marginLeft: 10, // Space between label and input
    width: 60, // Width to fit two characters
    fontSize: 18,
    borderRadius: 5,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start', // Align content to the top
    alignItems: 'center', // Center content horizontally
    backgroundColor: '#f5f5f5', // Background color for the entire page
  },
  messageBox: {
    marginTop: 100, // 100px from the top
    alignSelf: 'center', // Center box horizontally in the container
    padding: 20,
    backgroundColor: 'white', // Background color for the box
    borderRadius: 10, // Rounded corners for the box
    shadowColor: '#000', // Shadow for the box
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // Elevation for Android
  },
  messageText: {
    fontSize: 18, // Font size for the text
    textAlign: 'center', // Center text
    color: '#333', // Text color
  },
  taskText: {
    fontSize: 18,
    marginTop: 20,
    marginBottom: 10,
    color: '#333',
  },
  input: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    fontSize: 18,
    borderRadius: 5,
  },
  headersBox: {
    marginTop: 20, // 10px beneath the current box
    width: 370,
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5, // for Android shadow
  },
  headerName: {
    fontSize: 16,
    marginVertical: 5, // Space between header names
  },
});

export default LoggedInPage;
