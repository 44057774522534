import React, { useState, useEffect } from 'react';
import { 
    StyleSheet, Text, View, ScrollView, TouchableOpacity, Platform
} from 'react-native';

const InvoicesPage = ({ userEmail }) => {
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [itemDetails, setItemDetails] = useState({});

    useEffect(() => {
        if (Platform.OS === 'web') {
            window.history.pushState({}, '', '/invoices');
        }
        fetchInvoices();
    }, []);

    const fetchInvoices = async () => {
        try {
            let response = await fetch('https://skeba.info/costbrain/invoiceDisplay.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userEmail })
            });
            let json = await response.json();
    
            // Sort invoices by date in descending order (newest first)
            json.sort((a, b) => new Date(b.Invoice_Date) - new Date(a.Invoice_Date));
    
            setInvoices(json);
        } catch (error) {
            console.error('Error fetching invoices:', error);
        }
    };

    const fetchItemDetails = async (invoiceId) => {
        try {
            let response = await fetch('https://skeba.info/costbrain/itemsDisplay.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ Invoice_Id: invoiceId })
            });
            let json = await response.json();
            setItemDetails({ [invoiceId]: json }); // Store item details keyed by invoice ID
        } catch (error) {
            console.error('Error fetching item details:', error);
        }
    };

    const handleInvoiceClick = (invoiceId) => {
        // Check if the invoice is already selected to toggle details view
        if (selectedInvoiceId === invoiceId) {
            setSelectedInvoiceId(null);
            setItemDetails({}); // Clear item details when closing
        } else {
            setSelectedInvoiceId(invoiceId);
            fetchItemDetails(invoiceId);
        }
    };
 // <Text>Invoice ID: {invoice.Invoice_Id}</Text>
 //<Text>Item Number: {item.Item_Number}</Text>
    return (
      <View style={styles.container}>
          <Text style={styles.messageText}>Current Invoices</Text>
          <Text style={styles.emailText}>User Email: {userEmail}</Text>
          
          <ScrollView style={styles.invoiceContainer}>
              {invoices.map((invoice, index) => (
                  <View key={index}>
                      <TouchableOpacity style={styles.invoiceBox} onPress={() => handleInvoiceClick(invoice.Invoice_Id)}>
                      <Text>{invoice.Invoice_Date} || {invoice.Invoice_Number} || ${invoice.Invoice_Total}</Text>
                        
                          
                      
                      </TouchableOpacity>
                      {selectedInvoiceId === invoice.Invoice_Id && itemDetails[invoice.Invoice_Id] && (
                          <ScrollView style={styles.itemDetailScroll}>
                              <View style={styles.itemDetailContainer}>
                                  {itemDetails[invoice.Invoice_Id].map((item, itemIndex) => (
                                      <View key={itemIndex} style={styles.itemBox}>
                                          
                                          <Text>{item.Item_Name}</Text>
                                          <Text>{item.Item_Unit} || Qty: {item.Item_Qty} || ${item.Item_Price}</Text>
                                         
                                      </View>
                                  ))}
                              </View>
                          </ScrollView>
                      )}
                  </View>
              ))}
          </ScrollView>
      </View>
  );
};
const styles = StyleSheet.create({
  itemDetailScroll: {
    maxHeight: 200, // Set a maximum height for the items ScrollView
},
  itemDetailContainer: {
    backgroundColor: '#f9f9f9',
    padding: 10,
    marginTop: 10,
},
itemBox: {
    backgroundColor: '#dddddd',
    marginBottom: 10,
    padding: 10,
    borderRadius: 5,
},
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
    },
    messageBox: {
        marginTop: 100,
        alignSelf: 'center',
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    messageText: {
        fontSize: 18,
        textAlign: 'center',
        color: '#333',
    },
    invoiceContainer: {
        marginTop: 20,
    },
    invoiceBox: {
        backgroundColor: "#e3e3e3",
        padding: 10,
        marginTop: 10,
        borderRadius: 5,
    }
});

export default InvoicesPage;
