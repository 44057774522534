import { AppRegistry } from 'react-native';
import App from './App';
import appConfig from './app.json'; // Import the whole file

// Always import, but use conditionally
import { AppRegistry as AppRegistryWeb } from 'react-native-web';

// Extract the app name from appConfig
const appName = appConfig.name;

// Register the app component for native platforms
AppRegistry.registerComponent(appName, () => App);

// Check if running in a web environment
if (typeof window !== 'undefined' && window.document) {
    // Register the app component for the web platform
    AppRegistryWeb.registerComponent(appName, () => App);

    // Run the application on the web
    AppRegistryWeb.runApplication(appName, {
        initialProps: {},
        rootTag: document.getElementById('root') // Ensure your HTML has a div with id 'root'
    });
}
