import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Button, Image, TouchableOpacity, Platform, ImageBackground } from 'react-native'; // Import Platform here
import LoggedInPage from './LoggedInPage'; // Import the new component
import UserProfilePage from './UserProfilePage'; // Import the new UserProfilePage component
import InvoicesPage from './InvoicesPage'; // Import the new InvoicesPage component
import ItemsPage from './ItemsPage'; // Import the new InvoicesPage component

import AsyncStorage from '@react-native-async-storage/async-storage';

const backgroundImage = require('./taskpadbackground-min.png'); // Background image

const taskPadIcon = require('./assets/favicon.png'); // Update the path
const auth0ClientId = 'VgA4YJueC2aEI3U7B1dxHpwOCrXRYHQR';
const auth0Domain = 'dev-psw7nj5r7hp6q2lc.us.auth0.com';

export default function App() {

  const [userProfile, setUserProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');
  const fetchUserProfile = (accessToken) => {
    fetch(`https://${auth0Domain}/userinfo`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => response.json())
    .then(data => {
      // Assuming the user's email is in the data object
      setUserProfile(data);
      setIsLoggedIn(true);
      setCurrentPage('profile');
      AsyncStorage.setItem('userProfile', JSON.stringify(data));
    })
    .catch(error => {
      console.error('Error fetching user profile:', error);
    });
  };
  
  const extractAccessTokenFromUrl = () => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.slice(1)); // Remove '#' and parse
    return params.get('access_token');
  };
  
  useEffect(() => {
    // Function to load the user profile from storage
    const loadProfileFromStorage = async () => {
      try {
        const storedProfile = await AsyncStorage.getItem('userProfile');
        if (storedProfile) {
          setUserProfile(JSON.parse(storedProfile));
          setIsLoggedIn(true);
        }
      } catch (error) {
        console.error('Failed to load user profile from AsyncStorage', error);
      }
    };
  
    // Function to handle routing based on URL path
    const handleRouting = () => {
      const accessToken = extractAccessTokenFromUrl();
      if (accessToken) {
        AsyncStorage.setItem('accessToken', accessToken);
        fetchUserProfile(accessToken);
      } else {
        const path = window.location.pathname;
        setCurrentPage(setCurrentPageBasedOnPath(path));
      }
    };
  
    if (Platform.OS === 'web') {
      handleRouting();
      window.onpopstate = handleRouting;
    } else {
      loadProfileFromStorage();
    }
  
    return () => {
      if (Platform.OS === 'web') {
        window.onpopstate = null;
      }
    };
  }, [isLoggedIn]);
  
  const setCurrentPageBasedOnPath = (path) => {
    switch (path) {
      case '/profile':
        return isLoggedIn ? 'profile' : 'home';
      case '/invoices':
        return isLoggedIn ? 'invoices' : 'home';
      case '/home':
        return isLoggedIn ? 'home' : 'home';
      default:
        return 'home';
    }
  };
  const WebRoutes = () => (
    <Router>
      <Switch>
        <Route path="/profile">
          {isLoggedIn ? <UserProfilePage userProfile={userProfile} /> : <Redirect to="/" />}
        </Route>
        <Route path="/invoices">
          {isLoggedIn ? <InvoicesPage userEmail={userProfile.email} /> : <Redirect to="/" />}
        </Route>
        <Route path="/items">
          {isLoggedIn ? <ItemsPage userEmail={userProfile.email} /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/">
          {isLoggedIn ? <LoggedInPage userProfile={userProfile} /> : (
            <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
              <View style={styles.frontpageContainer}>
                <View style={styles.frontpageBox}>
                  <Text style={styles.taskText}>CostBrain:</Text>
                </View>
              </View>
            </ImageBackground>
          )}
        </Route>
      </Switch>
    </Router>
  );




  
  
  const callLoginEndpoint = (email) => {
    fetch('https://skeba.info/costbrain/login.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email })
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'created') {
        // Redirect user to /profile
        setCurrentPage('profile');
      } else if (data.status === 'exists') {
        // Redirect user to /loggedin
        setCurrentPage('loggedin');
      } else {
        // Handle error
        console.log(data.message || 'An error occurred');
      }
    })
    .catch(error => {
      console.error('Error calling login endpoint:', error);
    });
  };
  const checkPathAndSetPage = (path) => {
    if (path === '/profile' && isLoggedIn) {
      setCurrentPage('profile');
    } else {
      setCurrentPage('home');
    }
  };
  const login = () => {
    if (Platform.OS === 'web') {
      window.location.href = `https://${auth0Domain}/authorize?` +
        `scope=openid%20profile%20email&` +
        `response_type=token&` +
        `client_id=${auth0ClientId}&` +
        `redirect_uri=${window.location.origin}`;
    } else {
      // Native app login logic
    }
  };
  const logout = () => {
    // Clear local state and localStorage
    setIsLoggedIn(false);
    setUserProfile(null);
    setCurrentPage('home');
   AsyncStorage.removeItem('userProfile');
    // Log out from Auth0
    if (Platform.OS === 'web') {
        const returnTo = encodeURIComponent(window.location.origin);
        window.location.href = `https://${auth0Domain}/v2/logout?client_id=${auth0ClientId}&returnTo=${returnTo}`;
    }
};

  const goToProfile = () => {
    setCurrentPage('profile');
  };
  const goToHome = () => {
    setCurrentPage('home');
  };
  const goToInvoices= () => {
    setCurrentPage('invoices');
  };
  const goToItemsPage= () => {
    setCurrentPage('items');
  };
  return (

    <View style={styles.container}>
  <View style={styles.topBar}>
    <View style={styles.logoContainer}>
      <Image source={taskPadIcon} style={styles.icon} />
      <Text style={styles.title}>CostBrain</Text>
    </View>
    <View style={styles.rightSideContainer}>
      {isLoggedIn && (
        <TouchableOpacity onPress={goToHome} style={styles.profileButton}>
          <Text>Home</Text>
        </TouchableOpacity>
      )}
      {isLoggedIn && (
        <TouchableOpacity onPress={goToProfile} style={styles.profileButton}>
          <Text>Profile</Text>
        </TouchableOpacity>
      )}
           {isLoggedIn && (
        <TouchableOpacity onPress={goToInvoices} style={styles.profileButton}>
          <Text>Invoices</Text>
        </TouchableOpacity>
      )}
  {isLoggedIn && (
        <TouchableOpacity onPress={goToItemsPage} style={styles.profileButton}>
          <Text>Items</Text>
        </TouchableOpacity>
      )}
      {isLoggedIn ? (
        <TouchableOpacity onPress={logout} style={styles.loginButton}>
          <Text>Logout</Text>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity onPress={login} style={styles.loginButton}>
          <Text>Log In</Text>
        </TouchableOpacity>
      )}
    </View></View>
   
   
     {/* Render LoggedInPage if currentPage is 'loggedin' */}
     {currentPage === 'home' && isLoggedIn && <LoggedInPage userProfile={userProfile} />}
     {currentPage === 'profile' && isLoggedIn && <UserProfilePage userProfile={userProfile} />}


    {/* Render InvoicesPage if currentPage is 'invoices' */}
    {currentPage === 'invoices' && isLoggedIn && <InvoicesPage userEmail={userProfile.email} />}
    {currentPage === 'items' && isLoggedIn && <ItemsPage userEmail={userProfile.email} />}
    {currentPage === 'home' && !isLoggedIn && (
      <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
  <View>       

        <View style={styles.frontpageContainer}>
       

          <View style={styles.frontpageBox}>
          
            <Text style={styles.taskText}>CostBrain:</Text>
      
          </View>
        

    
        </View>
   





</View>     </ImageBackground>
      )}

    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#4b8a7c', // Purple background
  },
  profileButton: {
    padding: 10,
    backgroundColor: 'lightgray',
    borderRadius: 5,
    marginRight: 10,
  },
  backgroundImage: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  
  loginBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    // Add additional styling as needed
  },
  taskText: {
    fontSize: 18,
    marginTop: 20,
    marginBottom: 10,
    color: '#333',
  },
  input: {
    borderWidth: 1,
    borderColor: 'gray',
    padding: 10,
    fontSize: 18,
    borderRadius: 5,

  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  taskHeaderIcon: {
    width: 50, // Adjust size as needed
    height: 50, // Adjust size as needed
  },
  loginButton: {
    padding: 10,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  frontpageContainer: {
    flex: 1,
    flexDirection: 'row', // Align items in a row
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    right: '50%',
    top: 100,
  },
  frontpageBox: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    marginRight: 20, // Add space between the box and the image
    height:200,
    width:400,

  },
  registerButton: {
    marginTop: 10, // Space above the button
    padding: 10,
    backgroundColor: '#28a745',
    borderRadius: 5,
    backgroundColor: 'black',
    color: 'white',
  },
  boardImage: {
    width: 200, // Adjust size as needed
    height: 200, // Adjust size as needed
    borderRadius:5,
  },
  rightSideContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#333',
    padding: 10,
  },
  logoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  title: {
    fontSize: 20,
    color: 'white',
    // Add more styling for your title
  },

});